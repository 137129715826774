import { mapState, mapGetters, mapActions } from 'vuex'
import { state } from './modules/market'

export const marketComputed = {
    ...mapState('market', {
        marketTickers: state => state.tickers,
    }),
    ...mapGetters('market', {
        getMarketTicker: 'getTicker',
    })
}

export const marketMethods = mapActions('market', {
    updateMarketTickers: 'updateTickers',
})
